@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1;
  font-size: 14px; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000000; }

body * {
  font-family: Lato, sans-serif; }

p {
  font-size: 1rem;
  line-height: 1.5rem; }

a {
  text-decoration: none;
  color: black; }
  a:hover {
    color: black; }

h1 {
  font-size: 2.375rem;
  line-height: 2.625rem; }

h2 {
  font-size: 1.875rem;
  line-height: 2.25rem; }

h3 {
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: 20px; }

h4 {
  font-size: 1rem;
  line-height: 1.5rem; }

h5 {
  font-size: 0.9rem;
  line-height: 1.3rem; }

input,
textarea,
select,
button {
  margin: 0;
  box-sizing: border-box;
  color: #0A2533;
  border: none; }

input:focus {
  outline: none; }

input[type="text"],
input[type="email"],
input[type="password"] {
  border: 1px solid #E3E5EC;
  border-radius: 4px;
  height: 40px;
  padding: 10px; }
  input[type="text"]:disabled,
  input[type="email"]:disabled,
  input[type="password"]:disabled {
    background-color: #e3e5ec; }

.horizontal-radio-group {
  width: 100%;
  padding-bottom: 10px;
  height: 40px;
  margin-top: 10px; }
  .horizontal-radio-group .radio-option {
    float: left;
    padding: 5px;
    margin-bottom: 5px;
    margin-right: 20px; }
    .horizontal-radio-group .radio-option .paloma-radio-label {
      margin-left: 10px; }

.default-form__label {
  display: block;
  font-size: 12px;
  font-weight: bold;
  color: black;
  margin-bottom: 10px; }

.default-form__submit {
  display: block;
  min-width: 350px;
  height: 50px;
  height: 40px;
  border-radius: 4px;
  margin-top: 20px;
  font-size: 16px;
  color: #FFFFFF;
  background-color: #BA2A6B; }
  .default-form__submit:disabled {
    background-color: #E3E5EC; }

.edit-details-form {
  width: 350px; }

input.default-form__input,
.react-toggle.default-form__input {
  min-width: 350px;
  font-size: 14px;
  margin-bottom: 20px;
  border-radius: 0; }

button:hover {
  cursor: pointer; }

.paloma-button {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #E3E5EC;
  color: #345F7B;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  font-size: 12px;
  font-weight: bold;
  outline: inherit;
  padding: 10px 25px;
  margin-left: 10px; }
  .paloma-button:hover {
    background: #EFF1F5;
    color: #BA2A6B; }
  .paloma-button--pink {
    background: #BA2A6B;
    color: #FFFFFF;
    font: inherit;
    font-size: 12px;
    font-weight: bold;
    border-radius: 4px;
    border: 1px solid transparent;
    padding: 10px 20px; }
    .paloma-button--pink:hover {
      background: #BA2A6B;
      color: #FFFFFF;
      border: 1px solid transparent; }
  .paloma-button--pink-inverse {
    background: #FFFFFF;
    color: #BA2A6B;
    font-size: 12px;
    font-weight: bold;
    padding: 10px 20px; }
  .paloma-button.clear {
    background: none;
    color: white; }
  .paloma-button--pink:disabled {
    background: #e3e5ec;
    color: white; }
    .paloma-button--pink:disabled:hover {
      background: #e3e5ec;
      color: white;
      cursor: default; }
  .paloma-button:disabled {
    background: white;
    color: #e3e5ec; }
    .paloma-button:disabled:hover {
      background: white;
      color: #e3e5ec;
      cursor: default; }

.page-title {
  font-size: 2rem; }

.table-controls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 100%; }
  .table-controls__search {
    width: 300px; }

.subscribersList tr th:first-child {
  width: 30%; }

.subscribersList tr th:last-child {
  width: 13%; }

.subscribersList td {
  vertical-align: middle;
  width: 10%; }

.subscribersList strong {
  font-weight: bold; }

.conversation-detail-action-buttons .paloma-button {
  margin-left: 8px; }

.add-message-modal__message-types {
  display: flex;
  overflow-x: auto;
  width: 100%; }

.add-message-modal__message-type {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 200px;
  justify-content: flex-start;
  margin-right: 20px; }
  .add-message-modal__message-type:hover {
    cursor: pointer; }
  .add-message-modal__message-type:last-child {
    margin-right: 0; }

.add-message-modal__message-type-button {
  font-weight: normal;
  margin-bottom: 15px;
  padding: 6px 0;
  width: 85px; }

.add-message-modal__text-message {
  background-color: #EDEDED;
  border-radius: 16px;
  height: 35px;
  width: 110px; }

.add-message-modal__paste {
  border-radius: 16px;
  border: 1px dashed #BA2A6B;
  height: 35px;
  width: 110px; }

.add-message-modal__image-message {
  background-color: #EDEDED;
  border-radius: 16px;
  height: 130px;
  width: 150px; }

.add-message-modal__button-message-text {
  background-color: #EDEDED;
  border-radius: 16px;
  height: 35px;
  margin-bottom: 10px;
  width: 150px; }

.add-message-modal__button-message-template {
  background-color: #EDEDED;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 80px;
  width: 150px; }

.add-message-modal__button-message-button-top {
  background-color: #FFFFFF;
  border: 1px solid #EFF1F5;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 35px;
  width: 150px; }

.add-message-modal__button-message-button-middle {
  background-color: #FFFFFF;
  border: 1px solid #E3E5EC;
  border-top: none;
  height: 35px;
  width: 150px; }

.add-message-modal__button-message-button-bottom {
  background-color: #FFFFFF;
  border: 1px solid #E3E5EC;
  border-bottom-right-radius: 16px;
  border-top: none;
  height: 35px;
  width: 150px; }

.add-response-modal__response-types {
  width: 100%;
  display: flex;
  overflow-x: auto; }

.add-response-modal__response-type {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-right: 20px; }
  .add-response-modal__response-type:hover {
    cursor: pointer; }
  .add-response-modal__response-type:last-child {
    margin-right: 0; }

.add-response-modal__response-type-button {
  width: 85px;
  padding: 6px 0;
  font-weight: normal;
  margin-bottom: 15px; }

.add-response-modal__text-response {
  min-width: 110px;
  min-height: 35px;
  background-color: #2F80ED;
  border-radius: 16px;
  color: #FFFFFF;
  padding: 9px 26px;
  text-align: center; }

.builder-input__field {
  background: #FFFFFF;
  border: 1px solid #E3E5EC;
  border-radius: 3px;
  color: #000000;
  padding: 1px 4px; }

.builder-input__placeholder {
  color: #9e9e9e; }

.conversation-steps {
  padding: 20px;
  flex: 1;
  height: 0;
  overflow-y: auto; }

.subscriber-flow {
  color: #0A2533;
  padding: 20px;
  width: 30%; }
  .subscriber-flow .detour-divider span {
    width: 100%; }
  .subscriber-flow .step {
    margin: 10px 0px; }
    .subscriber-flow .step:last-child {
      margin-bottom: 0px; }
    .subscriber-flow .step.selected .label::before, .subscriber-flow .step.start .label::before {
      display: inline-block;
      content: " ";
      left: 0;
      top: 4px;
      height: 15px;
      width: 15px;
      border: 1px solid #0A2533;
      border-radius: 10px;
      position: absolute; }
    .subscriber-flow .step:not(.start) > .label {
      font-weight: 900; }
    .subscriber-flow .step > .label {
      padding: 0px 20px;
      position: relative; }
    .subscriber-flow .step > .label::before {
      display: inline-block;
      content: " ";
      left: 0;
      top: 4px;
      height: 10px;
      width: 10px;
      border: 1px solid #0A2533;
      border-radius: 10px;
      position: absolute;
      background: #345F7B; }
    .subscriber-flow .step .trigger,
    .subscriber-flow .step .field {
      display: flex;
      flex-direction: column;
      padding: 0px 20px;
      position: relative;
      margin: 10px 0px; }
    .subscriber-flow .step .trigger .detour-button {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .subscriber-flow .step .trigger .detour-button > .detour-section {
        border-left: none;
        display: block; }
      .subscriber-flow .step .trigger .detour-button:hover {
        cursor: pointer; }
    .subscriber-flow .step .trigger.selected .detour-button {
      color: #345F7B; }
    .subscriber-flow .step .trigger:not(.selected) .detour-button {
      color: #BA2A6B; }
    .subscriber-flow .step .trigger:first-child {
      margin-top: 10px; }

.targeting {
  margin-bottom: 30px;
  font-size: 1rem; }
  .targeting strong {
    font-weight: 600; }
  .targeting .list {
    margin: 10px 0px 20px; }
  .targeting .segment {
    background: #f0f1f5;
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-left: 10px; }
  .targeting input[type="radio"] {
    margin: 10px 10px 10px 0px; }

.targeting.white .segment {
  background: white; }

.unpublished-conversation-cta {
  margin: 0 auto;
  padding-top: 100px;
  text-align: center; }
  .unpublished-conversation-cta p {
    margin-bottom: 18px; }
  .unpublished-conversation-cta .unpublished-conversation-cta-buttons {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 212px; }

.modal .modal__header {
  color: #253d49; }

.modal .modal__content .modal__top-input .input-with-inline-button {
  margin: 0px 10px;
  position: relative; }
  .modal .modal__content .modal__top-input .input-with-inline-button button {
    position: absolute;
    height: 80%;
    right: 5px;
    top: 10%;
    padding: 0px;
    width: 30%; }
  .modal .modal__content .modal__top-input .input-with-inline-button input {
    width: 100%; }

.modal .modal__content .modal__fields .paloma-button {
  text-align: left;
  padding: 10px;
  display: block;
  margin: 10px; }
  .modal .modal__content .modal__fields .paloma-button.selected {
    background: #eff1f5;
    border-color: #253d49; }
    .modal .modal__content .modal__fields .paloma-button.selected:hover {
      color: #253d49; }

.modal .modal__content .modal__bottom {
  border-top: 1px solid #EFF1F5; }
  .modal .modal__content .modal__bottom button {
    margin: 7px 2%;
    width: 46%; }

.searchable-paginated-option-modal .modal__container {
  min-width: 588px; }

.searchable-paginated-option-modal .modal-pagination {
  min-height: 354px; }

.insert-field-modal .modal__content {
  max-width: 300px; }
  .insert-field-modal .modal__content .modal__fields {
    overflow: scroll;
    max-height: 200px; }

.conversation-detail .stat-header, .campaign-detail .stat-header {
  float: left;
  font-weight: bold; }

.conversation-detail h3 > .stat, .campaign-detail h3 > .stat {
  color: #B82D6A; }

.conversation-detail .stat, .campaign-detail .stat {
  float: right; }

.conversation-detail .stat-container, .campaign-detail .stat-container {
  clear: both;
  line-height: 2.5rem; }

h3 > .stat {
  color: #B82D6A;
  float: right; }

h3:last-child {
  margin: 0; }

.choose-step-modal .conversation-step:hover {
  box-shadow: 0 1px 5px -1px #777;
  cursor: pointer; }

.detour-divider {
  display: block;
  overflow: hidden;
  text-align: center;
  width: 100%; }
  .detour-divider > .detour-button:before {
    right: 100%; }
  .detour-divider > .detour-button:after {
    left: 100%; }
  .detour-divider > .detour-button:before, .detour-divider > .detour-button:after {
    background: #E3E5EC;
    content: "";
    height: 1px;
    position: absolute;
    top: 50%;
    width: 100vw; }

.detour-button {
  background-color: #EFF1F5;
  border-radius: 16px;
  border: 1px solid #E3E5EC;
  color: #345F7B;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 10px;
  position: relative; }
  .detour-button > .detour-section {
    border-left: 1px solid #E3E5EC;
    padding: 1px 5px;
    display: inline-block; }
    .detour-button > .detour-section:first-child {
      border-left: none; }
    .detour-button > .detour-section a {
      color: #BA2A6B;
      cursor: pointer; }

.matching-responses__list {
  padding: 0;
  margin: 0; }
  .matching-responses__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
    padding: 10px 15px;
    border: 1px solid #E3E5EC; }
    .matching-responses__list-item:last-child {
      margin-bottom: 0; }

.global-trigger-detail-actions-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .global-trigger-detail-actions-wrapper .info-panel {
    flex: 1 0 45%;
    margin: 0 10px 10px 0; }

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 60px;
  border-bottom: 1px solid #E3E5EC;
  padding: 20px; }
  .header--title {
    font-size: 24px;
    font-weight: 100;
    color: #345F7B; }

.navigation-sidebar {
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  align-self: stretch;
  justify-content: space-between;
  background-color: #0A2533;
  color: #345F7B;
  padding: 20px;
  font-size: 14px;
  font-weight: bolder; }
  .navigation-sidebar__home {
    display: block;
    padding: 15px;
    margin-top: 10px;
    width: 85%;
    height: 60px;
    color: #FFFFFF;
    font-size: 36px;
    background: url("../images/wordmark.png");
    background-size: cover;
    background-size: contain;
    background-repeat: no-repeat; }
    .navigation-sidebar__home:hover {
      color: white; }
  .navigation-sidebar__item {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    padding: 15px 10px;
    font-size: 12px;
    font-weight: normal;
    border-radius: 2px; }
    .navigation-sidebar__item:hover {
      background-color: rgba(52, 95, 123, 0.3);
      color: #FFFFFF; }
    .navigation-sidebar__item--active {
      background-color: #345F7B;
      color: #FFFFFF; }
      .navigation-sidebar__item--active:hover {
        background-color: #345F7B;
        color: #FFFFFF; }
    .navigation-sidebar__item.logout {
      position: relative; }
      .navigation-sidebar__item.logout .user-icon {
        position: absolute;
        right: 0;
        border: 2px solid #FFFFFF; }
  .navigation-sidebar__user {
    margin: auto 0 0 10px;
    background: none;
    border: none; }
  .navigation-sidebar .logout:hover {
    background-color: rgba(52, 95, 123, 0.3);
    color: #FFFFFF; }
  .navigation-sidebar .logout--active {
    background-color: #345F7B;
    color: #FFFFFF; }
    .navigation-sidebar .logout--active:hover {
      background-color: #345F7B;
      color: #FFFFFF; }

.breadcrumbs {
  list-style-type: none;
  padding: 12px;
  margin: 0; }
  .breadcrumbs__breadcrumb {
    display: inline-block;
    color: #345F7B;
    font-weight: 600;
    margin-right: 5px; }
    .breadcrumbs__breadcrumb:last-child:after {
      content: '';
      margin: 0; }
    .breadcrumbs__breadcrumb a {
      color: #345F7B; }
      .breadcrumbs__breadcrumb a:hover {
        color: #BA2A6B; }

.page-controls {
  list-style-type: none; }
  .page-controls__page-control {
    min-width: 125px;
    padding: 13px 0;
    display: inline-block;
    border-left: 1px solid #E3E5EC;
    text-transform: uppercase;
    text-align: center;
    font-size: 10px;
    font-weight: 600; }
    .page-controls__page-control a {
      color: #BA2A6B; }
    .page-controls__page-control--current {
      background: #345F7B; }
      .page-controls__page-control--current a {
        color: #FFFFFF; }

.alert {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
  z-index: 1000; }
  .alert--success {
    background: #d8e8e7;
    color: #19745f; }
  .alert--error {
    background: #e7dae1;
    color: #a4162a; }
  .alert__dismiss {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    font-size: 24px; }
    .alert__dismiss:hover {
      cursor: pointer; }

.message-history {
  background: #FFFFFF;
  overflow-y: scroll;
  overflow-x: hidden; }
  .message-history .message-container {
    padding: 10px; }
  .message-history .message-timestamp {
    text-align: center;
    font-size: .75rem;
    color: #858790;
    margin: 10px; }
  .message-history .message-content {
    max-width: 100%;
    display: flex;
    overflow: scroll; }
  .message-history .message-bubble-wrapper {
    display: flex;
    min-width: 100%; }
    .message-history .message-bubble-wrapper.paloma {
      justify-content: flex-end; }
  .message-history .message-bubble {
    border-radius: 18px;
    max-width: 100%;
    padding: 10px; }
    .message-history .message-bubble.subscriber {
      background-color: #EDEDED;
      color: black; }
    .message-history .message-bubble.paloma {
      background-color: #2F80ED;
      color: white;
      max-width: 60%;
      width: fit-content; }
    .message-history .message-bubble.sending {
      opacity: 0.5; }
  .message-history .generic-template {
    display: flex;
    justify-content: space-around;
    margin: auto;
    min-width: fit-content; }
    .message-history .generic-template .conversation-step__template-card {
      width: 170px;
      flex-basis: 170px;
      flex-grow: 0;
      flex-shrink: 0; }
    .message-history .generic-template .conversation-step__template-card:not(:first-child) {
      margin-left: 10px; }
    .message-history .generic-template .conversation-step__template-card__subtitle {
      max-height: 22px;
      overflow: hidden; }
  .message-history .image, .message-history .video {
    max-width: 100%; }
  .message-history .button {
    color: #2F80ED;
    padding: 10px;
    text-align: center;
    border-top: 1px solid #E3E5EC; }
  .message-history .button-template {
    width: 100%; }
    .message-history .button-template .buttons {
      float: right;
      margin: auto;
      min-width: fit-content;
      width: 170px;
      margin-top: 10px; }
      .message-history .button-template .buttons .button {
        border: 1px solid #E3E5EC;
        border-bottom: none;
        width: 170px; }
      .message-history .button-template .buttons .button:first-child {
        border-top-left-radius: 18px;
        border-top-right-radius: 18px; }
      .message-history .button-template .buttons .button:last-child {
        border-bottom: 1px solid #E3E5EC;
        border-bottom-left-radius: 18px;
        border-bottom-right-radius: 18px; }
  .message-history .quick-replies-container {
    overflow: scroll; }
  .message-history .quick-replies {
    display: flex;
    justify-content: space-around;
    min-width: fit-content; }
  .message-history .quick-reply {
    margin-top: 10px;
    border: none;
    color: #2f80ed;
    font-size: 1rem;
    font-weight: 600;
    height: auto;
    min-width: 100px;
    padding: 8px 8px 10px;
    text-align: center; }

.manual-message {
  border-top: 1px solid #EFF1F5;
  background: #FFFFFF; }
  .manual-message .toggles {
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    border-bottom: 1px solid #EFF1F5; }
    .manual-message .toggles .toggle-container {
      text-align: center;
      width: 50%;
      padding: 10px 0px; }
    .manual-message .toggles .toggle-container:not(:last-child) {
      border-right: 1px solid #EFF1F5; }
  .manual-message__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px; }
  .manual-message__input {
    border-radius: 4px;
    border: 1px solid #e3e5ec;
    font-size: .8rem;
    line-height: 1.4rem;
    min-height: 40px;
    padding: 8px; }
  .manual-message__controls {
    height: 50px;
    display: flex;
    justify-content: space-between;
    border-top: none;
    padding-top: 10px; }
    .manual-message__controls .paloma-button {
      margin-left: 0px; }
  .manual-message-tooltip .rc-tooltip-inner {
    max-width: 200px; }
  .manual-message .status-message {
    border-bottom: 1px solid #EFF1F5;
    padding: 10px;
    font-weight: bold; }

.subscriber-fields {
  width: 100%; }
  .subscriber-fields__fields-list {
    list-style-type: none;
    padding: 0;
    margin: 0; }
  .subscriber-fields__fields-list-item {
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #E3E5EC; }
    .subscriber-fields__fields-list-item:last-child {
      margin-bottom: 0; }
    .subscriber-fields__fields-list-item:hover {
      cursor: pointer;
      background-color: #EFF1F5; }
  .subscriber-fields__field-name {
    font-weight: bold; }

.subscriber-owner .select-owner {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 10px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer; }

.subscriber-owner.has-owner .select-owner {
  color: white;
  background-color: #092533;
  line-height: 35px;
  font-weight: bold; }

.subscriber-owner.new-owner .select-owner {
  color: #e3e5ec;
  background-color: white;
  border: 1px solid #e3e5ec;
  font-size: 30px; }

.subscriber-owner .modal__radio-label.selected {
  border: 1px solid #e3e5ec;
  background-color: #eff1f5; }

.action-button-overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0; }
  .action-button-overlay__button {
    background: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #E3E5EC;
    box-shadow: 0 1px 5px -1px #777;
    display: flex;
    overflow: hidden; }
  .action-button-overlay__text {
    background: transparent;
    border-right: 1px solid #E3E5EC;
    color: #345F7B;
    font-size: 12px;
    font-weight: 600;
    padding-left: 6px;
    padding-right: 6px; }
  .action-button-overlay__close-button {
    background: transparent;
    color: #345F7B;
    font-size: 18px;
    font-weight: 600;
    padding-left: 6px;
    padding-right: 6px; }

.arrow {
  display: inline-block;
  width: 0px;
  height: 0px;
  margin-left: 6px;
  padding-top: 5px; }
  .arrow.down {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid black;
    transform: translate(0, 3px); }
  .arrow.up {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid black;
    transform: translate(0, -3px); }

.button-row {
  box-shadow: 0 1px 5px -1px #777;
  display: flex;
  width: fit-content;
  list-style-type: none;
  border-radius: 4px; }
  .button-row__button {
    padding: 6px;
    border-right: 1px solid #E3E5EC;
    color: #345F7B;
    font-size: 14px;
    font-weight: 600;
    width: max-content; }
    .button-row__button:last-child {
      border-right: none; }
    .button-row__button:hover {
      background: #EFF1F5;
      cursor: pointer; }
    .button-row__button.active {
      background: #345E7B;
      color: #FFFFFF; }

.card {
  background: #FFFFFF;
  border: 1px solid #E3E5EC;
  padding: 15px;
  flex: 1; }
  .card.with-margin {
    margin-bottom: 12px; }
  .card__section {
    margin-top: 20px; }
  .card__section-title {
    font-weight: bold;
    margin-bottom: 15px; }

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px; }
  .content-header h2 {
    color: #0A2533;
    font-size: 20px; }
  .content-header h1 {
    display: inline-block; }
  .content-header h1, .content-header h2, .content-header h3 {
    margin-bottom: 0px; }
  .content-header div {
    display: flex;
    align-items: center; }
  .content-header .dropdown {
    margin: 0px; }

.dropup,
.dropdown {
  display: flex;
  position: relative;
  margin: 10px; }

.dropdown__button {
  margin: 0px; }
  .dropdown__button--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }

.dropdown__options {
  width: 100%;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  text-align: center; }
  .dropdown__options--open {
    display: block; }

.dropdown__option {
  background: #FFFFFF;
  color: #345F7B;
  border: 1px solid #E3E5EC;
  border-top: none;
  border-radius: 0;
  padding: 8px 25px;
  font: inherit;
  font-weight: 600;
  cursor: pointer;
  outline: inherit; }
  .dropdown__option:hover {
    background: #EFF1F5;
    color: #BA2A6B; }
  .dropdown__option:last-child {
    border-radius: 0 0 4px 4px; }

.dropup .dropdown__button--open {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px; }

.dropup .dropdown__options {
  bottom: 100%;
  top: unset; }

.dropup .dropdown__option {
  border: 1px solid #E3E5EC;
  border-bottom: none;
  border-radius: 0; }
  .dropup .dropdown__option:first-child {
    border-radius: 4px 4px 0 0; }

.error-container {
  border-radius: 10px;
  color: #A90F21;
  font-style: italic;
  margin: 10px 0px; }
  .error-container .error-icon {
    background: #A90F21;
    color: white;
    display: inline-block;
    border-radius: 1rem;
    width: 1rem;
    height: 1rem;
    text-align: center;
    margin-right: 10px; }

.show-errors .conversation-step__message-text.error {
  border: 1px solid #A90F21; }

.show-errors .conversation-step__template-card.error {
  border: 1px solid #A90F21; }

.show-errors .conversation-step-button__text.error {
  border: 1px solid #A90F21 !important;
  border-top: 1px solid #A90F21; }

.show-errors .quick-replies .quick-reply.error {
  border: 1px solid #A90F21; }

.hamburger {
  display: flex;
  flex-direction: column;
  width: 15px; }
  .hamburger__bar {
    height: 2px;
    width: 100%;
    margin-bottom: 2px;
    background-color: #345F7B;
    border-radius: 8px; }
    .hamburger__bar:last-child {
      margin-bottom: 0; }

.icon-dropdown__button {
  padding: 6px 5px; }
  .icon-dropdown__button--open {
    background: #EFF1F5;
    border: 1px solid #E3E5EC;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px; }

.icon-dropdown__options {
  width: fit-content;
  z-index: 2; }

.icon-dropdown__option {
  padding: 8px;
  font-weight: normal;
  white-space: nowrap; }
  .icon-dropdown__option:first-child {
    border-top: 1px solid #E3E5EC; }
  .icon-dropdown__option:last-child {
    border-radius: 0; }

.caret-dropdown__button {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  width: 100%;
  position: relative;
  padding: 10px 12px;
  border-radius: 0;
  color: black;
  font-weight: normal;
  text-align: left; }
  .caret-dropdown__button:hover {
    color: black;
    font-weight: normal; }
  .caret-dropdown__button:after {
    content: '▾';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    font-size: 14px;
    color: #345F7B; }

.caret-dropdown__options {
  width: fit-content;
  height: 0;
  position: absolute;
  display: block;
  opacity: 0; }
  .caret-dropdown__options--open {
    opacity: 1;
    height: auto; }

.caret-dropdown__option {
  width: 100%;
  min-width: 200px;
  height: 0;
  padding: 10px 12px;
  padding-top: 0;
  padding-bottom: 0;
  color: black;
  font-weight: normal;
  text-align: left;
  pointer-events: none; }
  .caret-dropdown__option:hover {
    color: black;
    font-weight: normal; }
  .caret-dropdown__option:last-child {
    border-radius: 0; }
  .caret-dropdown__option--open {
    display: block;
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    pointer-events: auto; }

.caret-down:after {
  content: '▾';
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-size: 14px;
  color: #345F7B; }

.info-panel {
  background-color: #FFFFFF;
  border: 1px solid #E3E5EC;
  box-sizing: border-box;
  padding: 15px;
  margin-bottom: 10px; }
  .info-panel__row {
    display: flex; }
    .info-panel__row:not(:last-child) {
      margin-bottom: 25px; }
    .info-panel__row--space-between {
      justify-content: space-between; }
    .info-panel__row--flex-start {
      justify-content: flex-start; }
  .info-panel__row--flex-start .info-panel__cell {
    padding-right: 50px; }
  .info-panel__cell h5 {
    font-weight: bold;
    margin-bottom: 8px; }

.inline-input-and-button {
  align-items: center;
  border: 1px solid #E3E5EC;
  display: flex;
  height: 40px;
  width: 100%; }
  .inline-input-and-button__input-container {
    flex: 1;
    padding: 10px; }
  .inline-input-and-button__button-container {
    display: inline-block;
    padding-right: 4px; }
  .inline-input-and-button__input[type=text] {
    border: 0;
    height: auto;
    border-radius: 0;
    padding: 0 0 0;
    width: 100%; }
  .inline-input-and-button__button.paloma-button, .inline-input-and-button__button.modal__button {
    height: 30px;
    line-height: 30px;
    padding: 0 20px;
    width: auto; }

.modal--closed {
  display: none; }

.modal__backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: none;
  opacity: 1;
  z-index: 9998; }

.modal__container {
  background: none;
  border: 1px solid #E3E5EC;
  display: flex;
  flex-direction: column;
  left: 50%;
  min-width: 300px;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999; }

.modal__header {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 15px;
  background-color: #EFF1F5;
  border-bottom: 1px solid #E3E5EC; }
  .modal__header--no-border {
    border-bottom: none; }

.modal__title {
  font-size: 16px;
  font-weight: bold;
  margin: 0; }

.modal__close {
  background: none;
  border: none;
  font-size: 32px;
  color: #BA2A6B; }
  .modal__close:hover {
    cursor: pointer; }

.modal__content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  text-align: center;
  background: white; }
  .modal__content--gray-background {
    background: #EFF1F5; }

.modal__form {
  text-align: left; }
  .modal__form--wide {
    width: 100%; }

.modal__fieldset {
  max-height: 250px;
  overflow-y: auto;
  padding: 15px;
  border: none; }

.modal__label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold; }

.modal__input {
  width: 100%;
  height: 40px;
  padding: 0 10px;
  margin-bottom: 10px;
  border: 1px solid #E3E5EC;
  font-size: 14px; }
  .modal__input--inline {
    width: 90%; }

.modal__controls {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #E3E5EC; }

.modal__button {
  width: 47%;
  height: 35px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: bold; }
  .modal__button:hover {
    cursor: pointer; }
  .modal__button--inline {
    position: relative;
    top: -39px;
    float: right;
    margin-right: 20px;
    padding-bottom: 24px;
    height: 30px; }

.modal__radio {
  display: none; }

.modal__radio-label {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 4px;
  border: 1px solid transparent; }
  .modal__radio-label:last-child {
    margin-bottom: 0; }
  .modal__radio-label:hover {
    border: 1px solid #E3E5EC;
    background-color: #EFF1F5;
    cursor: pointer; }
  .modal__radio-label--checked {
    border: 1px solid #345F7B;
    background-color: #EFF1F5;
    color: #345F7B; }
    .modal__radio-label--checked:hover {
      border: 1px solid #345F7B; }

.modal__top-input {
  margin-top: 10px;
  height: 60px; }

.modal__message {
  display: block;
  width: 100%;
  padding: 10px 10px 0; }
  .modal__message:last-of-type {
    margin-bottom: 10px; }

.modal__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #E3E5EC; }
  .modal__footer--no-border {
    border-bottom: none; }

.modal__error {
  color: #a4162a;
  font-weight: bold; }

.select-global-trigger-modal .modal__fieldset {
  font-weight: normal; }

.select-product-modal .modal__content {
  max-width: 100%; }

.select-product-modal .modal__radio-label > div {
  width: 100px; }

.select-product-modal .modal .modal-pagination .table__pagination-controls {
  text-align: right; }

.select-product-modal .modal__container {
  min-width: 780px; }

.object-info {
  width: 100%;
  height: 32px;
  clear: both;
  text-align: left; }
  .object-info .object-field {
    float: left;
    margin-right: 100px; }
  .object-info .object-field-name {
    font-weight: bold; }

.paloma-date-picker {
  width: 250px;
  max-height: 35px;
  position: relative; }
  .paloma-date-picker__date-picker {
    width: 250px;
    max-height: 35px; }
  .paloma-date-picker__calendar-icon {
    background-image: url("../images/ic_calendar.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: white;
    width: 40px;
    height: 18px;
    position: absolute;
    top: 8px;
    right: 4px;
    pointer-events: none; }

.placeholder {
  width: 100%;
  background: #EFF1F5;
  border: none;
  border-radius: 4px;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

input.search__input {
  width: 100%;
  height: 35px;
  display: inline-block;
  padding: 10px 34px 10px 12px;
  border-radius: 0;
  font-size: 12px;
  background-image: url("../images/magnifying-glass-icon.svg");
  background-repeat: no-repeat;
  background-position: 270px center; }

.separated-button-row {
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  padding-bottom: 15px;
  position: absolute;
  z-index: 1000; }
  .separated-button-row .button {
    align-items: center;
    background-color: white;
    border-radius: 4px;
    border: 1px solid #E3E5EC;
    box-shadow: 0 3px 5px #ccc;
    color: #325E7C !important;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    margin-right: 7px;
    padding: 5px 8px;
    width: max-content;
    white-space: nowrap; }
    .separated-button-row .button:hover {
      background-color: #E3E5EC;
      cursor: pointer; }
    .separated-button-row .button.no-border {
      border: none;
      box-shadow: none; }
    .separated-button-row .button.selected {
      background-color: #325E7C;
      border: none;
      color: #FFFFFF !important; }
    .separated-button-row .button.close {
      margin-left: 10px;
      font-size: 1.5rem;
      padding: 0; }
      .separated-button-row .button.close:hover {
        background-color: transparent; }

.table {
  table-layout: fixed;
  width: 100%;
  text-align: left;
  border-collapse: separate;
  border-spacing: 0 10px; }
  .table.failures td {
    overflow: hidden;
    text-overflow: ellipsis; }
  .table__header {
    color: black;
    font-weight: bold;
    padding-bottom: 5px;
    font-size: 12px; }
    .table__header:first-child {
      padding-left: 20px; }
    .table__header .sortable {
      color: #325e7c; }
      .table__header .sortable:hover {
        cursor: pointer; }
      .table__header .sortable.selected {
        color: black; }
  .table__row {
    width: 100%;
    border: 1px solid #EFF1F5; }
    .table__row:last-child {
      margin-bottom: 0; }
  .table__data {
    padding: 17px 0;
    border: 1px solid #EFF1F5;
    border-left: none;
    border-right: none;
    min-width: 50px;
    overflow: hidden;
    font-size: 12px; }
    .table__data:first-child {
      border-left: 1px solid #EFF1F5;
      padding-left: 20px; }
    .table__data:last-child {
      min-width: 0;
      text-align: right;
      border-right: 1px solid #EFF1F5;
      padding-right: 20px; }
  .table__link {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    margin-right: 30px;
    font-size: 12px;
    font-weight: bold;
    color: #BA2A6B; }
    .table__link:hover {
      color: #BA2A6B;
      cursor: pointer; }
    .table__link:last-child {
      margin-right: 0; }
  .table__pagination-controls {
    text-align: right; }
  .table__pagination-arrow-button {
    border: none;
    padding: 4px 8px; }
    .table__pagination-arrow-button:hover {
      background: white; }
  .table__pagination-page-button {
    padding: 4px 8px;
    margin: auto 5px; }
    .table__pagination-page-button:last-child {
      margin-right: 0; }
    .table__pagination-page-button--current {
      border: 1px solid #345F7B;
      background: #EFF1F5; }
  .table__pagination-ellipsis {
    color: #345F7B;
    margin: auto 5px; }

.empty-table__button {
  margin-left: 0;
  margin-top: 10px; }

.modal .modal-pagination {
  width: 400px; }
  .modal .modal-pagination .table__pagination-controls {
    text-align: center;
    margin: 5px; }
  .modal .modal-pagination table tbody {
    display: flex;
    flex-direction: column;
    margin: 5px; }
  .modal .modal-pagination table .modal__radio-label {
    color: #ba2a6b;
    font-weight: 510;
    border: 1px solid #e3e5ec; }
  .modal .modal-pagination table .modal__radio-label--checked {
    border: 1px solid #345f7b;
    background-color: #eff1f5;
    color: #345f7b; }

.tab-select {
  background: white;
  border-radius: 4px;
  border: 1px solid #E3E5EC;
  font-size: 12px; }
  .tab-select a {
    text-transform: capitalize;
    display: inline-block;
    background: white;
    border-left: 0px;
    padding: 10px 20px;
    color: #BA2A6B; }
    .tab-select a:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .tab-select a:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }
    .tab-select a:hover {
      cursor: pointer; }
    .tab-select a.selected {
      background: #335e7b;
      color: white;
      box-shadow: 0px 0px 1px #d6d6d6; }

.tag-select__tag {
  display: inline-block;
  position: relative;
  background-color: #fbead1;
  border-radius: 4px;
  border: 1px solid #ffac33;
  color: black;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top; }
  .tag-select__tag:hover {
    cursor: pointer; }
  .tag-select__tag--selected {
    background-color: #fee3ef;
    border: 1px solid #BA2A6B; }

.tag-select__tag-label {
  display: inline-block;
  vertical-align: middle;
  padding: 4px 5px; }

.tag-select__delete-button {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(33%, -33%);
  padding: 0;
  width: 14px;
  height: 14px;
  line-height: 15px;
  background: #BA2A6B;
  border: none;
  border-radius: 32px; }
  .tag-select__delete-button:hover {
    cursor: pointer; }

.tag-select__delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 16px;
  font-size: 13px;
  color: white; }

.tag-select.is-focused:not(.is-open) > .Select-control {
  border-color: #E3E5EC;
  box-shadow: none; }

.tag-select .Select-placeholder {
  line-height: 40px; }

.tag-select .Select-control,
.tag-select .Select-input {
  height: 40px; }

.tag-select .Select-control {
  border-color: #E3E5EC; }
  .tag-select .Select-control:hover {
    box-shadow: none; }

.tag-select .Select-input > input {
  padding: 10px 0 12px; }

.tag-select .Select-menu-outer {
  border-color: #E3E5EC; }

.react-toggle {
  margin: 0px 8px; }

.react-toggle,
.react-toggle:hover {
  color: white;
  vertical-align: middle; }
  .react-toggle .react-toggle-track,
  .react-toggle:hover .react-toggle-track {
    align-items: center;
    background-color: #eff1f5;
    color: #325e7c;
    display: flex;
    justify-content: flex-end;
    padding: 0px 6px;
    width: 55px; }
    .react-toggle .react-toggle-track .react-toggle-track-x,
    .react-toggle .react-toggle-track .react-toggle-track-check,
    .react-toggle:hover .react-toggle-track .react-toggle-track-x,
    .react-toggle:hover .react-toggle-track .react-toggle-track-check {
      font-size: 0.7rem;
      font-weight: bold;
      position: initial;
      width: auto;
      height: auto; }
  .react-toggle .react-toggle-thumb,
  .react-toggle:hover .react-toggle-thumb {
    left: 3px;
    top: 2px;
    height: 20px;
    width: 20px;
    border: none;
    box-shadow: 0 0 5px #555; }
  .react-toggle:not(.react-toggle--disabled) .react-toggle-track,
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    color: #325e7c;
    background-color: #eff1f5; }

.react-toggle--checked:not(.react-toggle--disabled) .react-toggle-track,
.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  color: white;
  background-color: #69c787; }

.react-toggle--checked .react-toggle-thumb,
.react-toggle--checked:hover .react-toggle-thumb {
  left: 33px; }

.react-toggle--checked .react-toggle-track,
.react-toggle--checked:hover .react-toggle-track {
  justify-content: flex-start; }

.react-toggle.square .react-toggle-track,
.react-toggle.square:hover .react-toggle-track {
  border-radius: 4px; }
  .react-toggle.square .react-toggle-track .react-toggle-track-x,
  .react-toggle.square:hover .react-toggle-track .react-toggle-track-x {
    border-radius: 4px; }

.react-toggle.square .react-toggle-thumb,
.react-toggle.square:hover .react-toggle-thumb {
  border-radius: 4px; }

.react-toggle--checked.standby .react-toggle-track,
.react-toggle--checked.standby:hover .react-toggle-track {
  background: #f9a956; }

.user-icon {
  background-color: #EDEDED;
  background-position: center;
  background-size: cover;
  border-radius: 32px;
  height: 32px;
  width: 32px;
  min-height: 32px;
  max-height: 32px;
  min-width: 32px;
  max-width: 32px; }

.links-table .url a {
  color: #b82d6a;
  font-weight: bold; }

.links-table .table__header:nth-child(2) {
  padding-left: 20px; }

.links-table .table__data:nth-child(2) {
  padding-left: 20px; }

.link-detail .link {
  color: #b82d6a;
  font-weight: bold; }

.rc-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  line-height: 1.5;
  font-size: 12px; }
  .rc-tooltip-hidden {
    display: none; }
  .rc-tooltip pre {
    background-color: #0D2630;
    border-left: none; }

.rc-tooltip-inner {
  background-color: #0D2630;
  border-radius: 5px;
  box-shadow: 0px 3px 3px 0px #cccccc;
  color: #FFFFFF;
  line-height: 14px;
  padding: 10px; }

.rc-tooltip-placement-bottom {
  padding: 9px 0 5px 0; }

.rc-tooltip-placement-bottom .rc-tooltip-arrow {
  top: 4px;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #0B2532; }

.rc-tooltip-arrow {
  border-color: transparent;
  border-style: solid;
  border-top-color: #373737;
  border-width: 5px 5px 0;
  bottom: -4px;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  width: 0; }

div:hover .rc-tooltip {
  display: block !important; }

button[data-balloon] {
  overflow: visible; }

[data-balloon] {
  position: relative;
  cursor: pointer; }

[data-balloon]:after {
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.18s ease-out 0.18s;
  -moz-transition: all 0.18s ease-out 0.18s;
  -ms-transition: all 0.18s ease-out 0.18s;
  -o-transition: all 0.18s ease-out 0.18s;
  transition: all 0.18s ease-out 0.18s;
  font-family: sans-serif !important;
  font-weight: normal !important;
  font-style: normal !important;
  text-shadow: none !important;
  font-size: 12px !important;
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  color: #fff;
  content: attr(data-balloon);
  padding: .5em 1em;
  position: absolute;
  white-space: nowrap;
  z-index: 10; }

[data-balloon]:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(0)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  filter: alpha(opacity=0);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.18s ease-out 0.18s;
  -moz-transition: all 0.18s ease-out 0.18s;
  -ms-transition: all 0.18s ease-out 0.18s;
  -o-transition: all 0.18s ease-out 0.18s;
  transition: all 0.18s ease-out 0.18s;
  content: '';
  position: absolute;
  z-index: 10; }

[data-balloon]:hover:before, [data-balloon]:hover:after, [data-balloon][data-balloon-visible]:before, [data-balloon][data-balloon-visible]:after {
  filter: alpha(opacity=100);
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
  pointer-events: auto; }

[data-balloon].font-awesome:after {
  font-family: FontAwesome; }

[data-balloon][data-balloon-break]:after {
  white-space: pre; }

[data-balloon][data-balloon-blunt]:before, [data-balloon][data-balloon-blunt]:after {
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none; }

[data-balloon][data-balloon-pos="up"]:after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 11px;
  -webkit-transform: translate(-50%, 10px);
  -moz-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

[data-balloon][data-balloon-pos="up"]:before {
  bottom: 100%;
  left: 50%;
  margin-bottom: 5px;
  -webkit-transform: translate(-50%, 10px);
  -moz-transform: translate(-50%, 10px);
  -ms-transform: translate(-50%, 10px);
  transform: translate(-50%, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

[data-balloon][data-balloon-pos="up"]:hover:after, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

[data-balloon][data-balloon-pos="up"]:hover:before, [data-balloon][data-balloon-pos="up"][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

[data-balloon][data-balloon-pos="up-left"]:after {
  bottom: 100%;
  left: 0;
  margin-bottom: 11px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

[data-balloon][data-balloon-pos="up-left"]:before {
  bottom: 100%;
  left: 5px;
  margin-bottom: 5px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

[data-balloon][data-balloon-pos="up-left"]:hover:after, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos="up-left"]:hover:before, [data-balloon][data-balloon-pos="up-left"][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos="up-right"]:after {
  bottom: 100%;
  right: 0;
  margin-bottom: 11px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

[data-balloon][data-balloon-pos="up-right"]:before {
  bottom: 100%;
  right: 5px;
  margin-bottom: 5px;
  -webkit-transform: translate(0, 10px);
  -moz-transform: translate(0, 10px);
  -ms-transform: translate(0, 10px);
  transform: translate(0, 10px);
  -webkit-transform-origin: top;
  -moz-transform-origin: top;
  -ms-transform-origin: top;
  transform-origin: top; }

[data-balloon][data-balloon-pos="up-right"]:hover:after, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos="up-right"]:hover:before, [data-balloon][data-balloon-pos="up-right"][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos='down']:after {
  left: 50%;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px); }

[data-balloon][data-balloon-pos='down']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 50%;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(-50%, -10px);
  -moz-transform: translate(-50%, -10px);
  -ms-transform: translate(-50%, -10px);
  transform: translate(-50%, -10px); }

[data-balloon][data-balloon-pos='down']:hover:after, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:after {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

[data-balloon][data-balloon-pos='down']:hover:before, [data-balloon][data-balloon-pos='down'][data-balloon-visible]:before {
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

[data-balloon][data-balloon-pos='down-left']:after {
  left: 0;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px); }

[data-balloon][data-balloon-pos='down-left']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  left: 5px;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px); }

[data-balloon][data-balloon-pos='down-left']:hover:after, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos='down-left']:hover:before, [data-balloon][data-balloon-pos='down-left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos='down-right']:after {
  right: 0;
  margin-top: 11px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px); }

[data-balloon][data-balloon-pos='down-right']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(180 18 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 18px;
  height: 6px;
  right: 5px;
  margin-top: 5px;
  top: 100%;
  -webkit-transform: translate(0, -10px);
  -moz-transform: translate(0, -10px);
  -ms-transform: translate(0, -10px);
  transform: translate(0, -10px); }

[data-balloon][data-balloon-pos='down-right']:hover:after, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos='down-right']:hover:before, [data-balloon][data-balloon-pos='down-right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0); }

[data-balloon][data-balloon-pos='left']:after {
  margin-right: 11px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  transform: translate(10px, -50%); }

[data-balloon][data-balloon-pos='left']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(-90 18 18)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  margin-right: 5px;
  right: 100%;
  top: 50%;
  -webkit-transform: translate(10px, -50%);
  -moz-transform: translate(10px, -50%);
  -ms-transform: translate(10px, -50%);
  transform: translate(10px, -50%); }

[data-balloon][data-balloon-pos='left']:hover:after, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%); }

[data-balloon][data-balloon-pos='left']:hover:before, [data-balloon][data-balloon-pos='left'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%); }

[data-balloon][data-balloon-pos='right']:after {
  left: 100%;
  margin-left: 11px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  -moz-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%); }

[data-balloon][data-balloon-pos='right']:before {
  background: no-repeat url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http://www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba(17, 17, 17, 0.9)%22%20transform%3D%22rotate(90 6 6)%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  width: 6px;
  height: 18px;
  left: 100%;
  margin-left: 5px;
  top: 50%;
  -webkit-transform: translate(-10px, -50%);
  -moz-transform: translate(-10px, -50%);
  -ms-transform: translate(-10px, -50%);
  transform: translate(-10px, -50%); }

[data-balloon][data-balloon-pos='right']:hover:after, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:after {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%); }

[data-balloon][data-balloon-pos='right']:hover:before, [data-balloon][data-balloon-pos='right'][data-balloon-visible]:before {
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%); }

[data-balloon][data-balloon-length='small']:after {
  white-space: normal;
  width: 80px; }

[data-balloon][data-balloon-length='medium']:after {
  white-space: normal;
  width: 150px; }

[data-balloon][data-balloon-length='large']:after {
  white-space: normal;
  width: 260px; }

[data-balloon][data-balloon-length='xlarge']:after {
  white-space: normal;
  width: 380px; }

@media screen and (max-width: 768px) {
  [data-balloon][data-balloon-length='xlarge']:after {
    white-space: normal;
    width: 90vw; } }

[data-balloon][data-balloon-length='fit']:after {
  white-space: normal;
  width: 100%; }

.notice {
  background-color: #345F7B;
  border: 1px solid #E3E5EC;
  position: relative;
  border-radius: 5px;
  padding: 20px;
  margin: 10px 10px; }
  .notice--success {
    background: #B7E0C6;
    color: #FFFFFF; }
  .notice--error {
    background-color: #BA2A6B;
    color: #FFFFFF; }
  .notice--default {
    background-color: #EFF1F5;
    border-color: #E3E5EC;
    color: #0A2533; }
  .notice--white {
    background-color: #FFFFFF;
    border-color: #E3E5EC; }
  .notice--compact {
    padding: 10px;
    font-size: .8em; }
  .notice__dismiss {
    position: absolute;
    top: 0px;
    right: 0px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1.5rem;
    font-weight: 700; }
    .notice__dismiss:hover {
      cursor: pointer; }
  .notice__message {
    line-height: 1.2rem;
    max-width: 850px; }
  .notice__headline {
    font-weight: 700;
    margin-bottom: .5rem; }
  .notice a {
    border-bottom: 1px solid #000000; }

.time {
  white-space: pre-line;
  font-size: 10px !important; }

.text-xs {
  font-size: 12px; }

.text-sm {
  font-size: 14px; }

.text {
  font-size: 16px; }

.text-md {
  font-size: 18px; }

.text-lg {
  font-size: 20px; }

.text-xl {
  font-size: 24px; }

.text-bold {
  font-weight: bold; }

.campaigns .table .actions {
  display: flex;
  justify: space-between; }
  .campaigns .table .actions .table__link {
    margin: auto; }

.messages {
  display: flex;
  flex-direction: column; }
  .messages .tab-select-container {
    display: flex;
    justify-content: flex-begin;
    margin-bottom: 30px; }
  .messages .message-detail {
    border: 1px solid #EFF1F5;
    margin: 5px 0;
    padding: 10px;
    font-size: 12px; }
    .messages .message-detail .details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px; }
      .messages .message-detail .details .sent-by {
        font-weight: bold; }
        .messages .message-detail .details .sent-by a {
          color: #BA2A6B; }
    .messages .message-detail .message {
      border-radius: 18px;
      display: inline-block;
      margin: 5px;
      padding: 10px;
      width: auto;
      overflow: hidden; }
      .messages .message-detail .message.subscriber {
        background-color: #2f80ed;
        color: white; }
      .messages .message-detail .message .image {
        background: white; }

.conversations .conversation {
  border: 1px solid #EFF1F5;
  margin: 5px 0;
  padding: 20px;
  display: flex; }
  .conversations .conversation .label {
    flex: 0 0 60%; }
  .conversations .conversation .last-ran-at {
    flex: 0 0 25%; }
  .conversations .conversation .link {
    flex: 0 0 15%;
    text-align: right; }
  .conversations .conversation a {
    color: #B82D6A;
    font-weight: bold; }

.persistent-menu-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 50px; }
  .persistent-menu-wrapper .dropdown {
    align-self: start; }

.persistent-menu {
  width: 300px;
  position: relative;
  margin-bottom: 20px; }
  .persistent-menu .header {
    font-weight: bold;
    justify-content: center;
    border-top: 1px solid #E3E5EC; }
  .persistent-menu .item, .persistent-menu .header {
    border: 1px solid #E3E5EC;
    padding: 20px; }
  .persistent-menu .item {
    border-top: none; }
    .persistent-menu .item input {
      color: #3A86E7;
      font-size: 1rem;
      width: 100%; }

.persistent-menu-builder {
  display: flex;
  justify-content: space-around; }

code, samp, kbd {
  font-family: "Courier New", Courier, monospace, sans-serif;
  text-align: left;
  color: #555; }

pre code {
  line-height: 1.6em;
  font-size: 11px; }

pre {
  background-color: #FAFAFA;
  padding: 0.1em 0.5em 0.3em 0.7em;
  border-left: 11px solid #ccc;
  margin: 1.7em 0 1.7em 0.3em;
  overflow: auto;
  min-width: 200px; }

/* target IE7 and IE6 */
*:first-child + html pre {
  padding-bottom: 2em;
  overflow-y: hidden;
  overflow: visible;
  overflow-x: auto; }

* html pre {
  padding-bottom: 2em;
  overflow: visible;
  overflow-x: auto; }

h1 input {
  font-size: inherit;
  background: inherit;
  width: 100%; }

.layout {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column; }
  .layout__main-container {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start; }
  .layout__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    align-self: stretch;
    background: #EFF1F5;
    overflow-y: auto; }
  .layout__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E3E5EC;
    min-height: 37px;
    font-size: 12px; }
    .layout__header:empty {
      min-height: 0;
      border-bottom: none; }
  .layout__page {
    width: 100%;
    flex: 1;
    padding-top: 20px; }
    .layout__page > div {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      padding: 12px;
      height: 100%; }
    .layout__page .default-tag {
      margin-right: 20px;
      color: #777; }
    .layout__page .description p {
      margin-right: 50px; }
    .layout__page .object-info {
      margin-bottom: 30px; }
    .layout__page .empty-table-text {
      margin-bottom: 20px; }

.dynamic-message-builder {
  padding: 0;
  min-height: 70%; }
  .dynamic-message-builder .dynamic-message-header {
    color: #345F7B;
    font-weight: bold;
    padding: 20px;
    padding-bottom: 30px; }
  .dynamic-message-builder .scenario-builder {
    background: white;
    float: right; }
  .dynamic-message-builder .conversation-builder__main {
    height: 100%; }
  .dynamic-message-builder .conversation-builder,
  .dynamic-message-builder .scenario-builder {
    width: 50%;
    display: inline-block;
    height: 100%;
    max-height: 100%;
    border: none; }
  .dynamic-message-builder .controls {
    position: absolute;
    bottom: 0;
    z-index: 1000; }
  .dynamic-message-builder .scenario-builder .controls {
    bottom: 20px; }
  .dynamic-message-builder .builder-panels-wrapper {
    height: 100%; }
  .dynamic-message-builder .builder-panels {
    height: 750px;
    max-height: 750px; }
    @media screen and (min-height: 800px) {
      .dynamic-message-builder .builder-panels {
        height: 800px;
        max-height: 800px; } }
    @media screen and (min-height: 900px) {
      .dynamic-message-builder .builder-panels {
        height: 850px;
        max-height: 850px; } }
    .dynamic-message-builder .builder-panels .flow {
      border-right: 1px solid #E3E5EC;
      border-top: 1px solid #E3E5EC;
      padding-bottom: 20px;
      width: 50%; }
      .dynamic-message-builder .builder-panels .flow .conversation-step-container {
        overflow: scroll; }
    .dynamic-message-builder .builder-panels h2 {
      margin: 20px 0px;
      text-align: center; }
    .dynamic-message-builder .builder-panels .conversation-builder {
      height: 100%;
      float: left; }
  .dynamic-message-builder .controls .paste-dynamic-step-button {
    margin-left: 5px;
    border: 1px dashed #ba2a6b !important;
    background: white !important;
    color: #ba2a6b !important; }
  .dynamic-message-builder .section.add-message {
    background: white; }
  .dynamic-message-builder .conversation-steps {
    height: 100%; }
    .dynamic-message-builder .conversation-steps .conversation-step {
      min-width: 100%; }
  .dynamic-message-builder .separated-button-row {
    position: relative; }
  .dynamic-message-builder .scenario {
    border: 1px solid #E3E5EC;
    margin: 15px; }
    .dynamic-message-builder .scenario .subtract {
      padding: 0px;
      width: 34px;
      height: 34px; }
    .dynamic-message-builder .scenario .scenario-header {
      border-bottom: 1px solid #E3E5EC;
      display: flex;
      flex-direction: row;
      font-weight: bold;
      justify-content: space-between;
      padding: 15px; }
      .dynamic-message-builder .scenario .scenario-header .actions {
        color: #345F7B; }
        .dynamic-message-builder .scenario .scenario-header .actions span {
          padding: 7px; }
    .dynamic-message-builder .scenario .scenario-body {
      padding: 15px; }
    .dynamic-message-builder .scenario table {
      width: 100%; }
      .dynamic-message-builder .scenario table th {
        font-weight: bold;
        text-align: left; }
      .dynamic-message-builder .scenario table td,
      .dynamic-message-builder .scenario table th {
        padding: 5px; }

.account .field {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  align-items: center; }
  .account .field input {
    font-size: .9rem; }

.account .help-text {
  font-size: .7rem;
  padding: 0px 8px;
  color: #666;
  font-style: italic; }

.flash {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
  width: 100%;
  height: 100%;
  position: relative;
  background: #305e7e;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #305e7e 0%, #ba2a6b 50%, #ffad18 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #305e7e 0%, #ba2a6b 50%, #ffad18 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #305e7e 0%, #ba2a6b 50%, #ffad18 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#305e7e', endColorstr='#ffad18',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  .flash .paloma {
    position: absolute;
    top: 20px;
    left: 20px;
    color: white;
    font-size: 28px; }
  .flash .flash-container {
    text-align: center; }
    .flash .flash-container h1 {
      margin-bottom: 20px; }

.audience {
  margin-right: 300px;
  padding-right: 0px !important; }
  .audience__show-subscribers-toggle {
    width: 200px;
    height: 35px; }
  .audience__new-list {
    padding: 8px 10px; }
  .audience .split {
    display: flex;
    flex-direction: row; }
    .audience .split .list {
      flex: 1 1 50%;
      margin-right: 12px; }
      .audience .split .list tr.table__row.selected,
      .audience .split .list tr.table__row:hover {
        background: #f7f8fb; }
      .audience .split .list tr.table__row.unread .table__data:first-child {
        border-left: solid 5px #BA2A6B; }
      .audience .split .list .manual {
        background: #EDEDED;
        border-radius: 15px;
        display: block;
        height: 15px;
        margin-left: 25px;
        width: 15px;
        border: 2px solid #E3E5EC; }
        .audience .split .list .manual.enabled {
          background: #6fc58b;
          border-color: #6fc58b; }

.card.profile {
  width: 300px;
  position: fixed;
  top: 0px;
  right: 0px;
  padding: 0px;
  height: 100%; }
  .card.profile .profile-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column; }
  .card.profile .avatar-and-name {
    display: flex;
    align-items: center; }
  .card.profile .user-icon {
    display: inline-block;
    margin-right: 5px; }
  .card.profile .message-history {
    overflow-y: scroll; }

.profile-header {
  min-height: 50px;
  border-bottom: 1px solid #E3E5EC;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; }

.status-dot {
  background: #E3E5EC;
  border-radius: 15px;
  border: 2px solid #E3E5EC;
  display: block;
  height: 11px;
  margin-left: 15px;
  width: 11px; }
  .status-dot.small {
    height: 14px;
    width: 14px; }
  .status-dot.float {
    position: absolute;
    top: 30px;
    left: 15px; }
  .status-dot.active-plus-one {
    background-color: #fbebd0;
    border-color: #feb025; }
  .status-dot.available {
    background-color: #b3e5c2;
    border-color: #69c787; }
  .status-dot.active {
    background-color: #97adbe;
    border-color: #325e7e; }
  .status-dot.can-reactivate {
    background-color: #f1d4e1;
    border-color: #c5467f; }
  .status-dot .inactive {
    background-color: #EFF1F5;
    border-color: #858790; }
  .status-dot.manual-messaging {
    background-color: #B7E0C6;
    border-color: #80CA99; }
  .status-dot.unsubscribed {
    background-color: #FFFFFF;
    border-color: #e3e5ec; }
  .status-dot.automated {
    background-color: #9baeba;
    border-color: #4c7087; }
  .status-dot.standby {
    background-color: #fcd3a6;
    border-color: #f7ac5e; }

.chat-status {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #EDEDED;
  color: #0A2533;
  font-size: 8px;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 4px;
  text-transform: uppercase; }
  .chat-status.auto {
    background-color: #EFF1F5;
    color: #345F7B; }

.impersonating .audience .split .card.profile {
  top: 56px; }
  .impersonating .audience .split .card.profile .manual-message {
    margin-bottom: 56px; }

.impersonating .subscriber-detail__right-column {
  top: 56px; }

.subscriber-list-buttons {
  margin-right: 12px; }
  .subscriber-list-buttons .paloma-button {
    margin-left: 10px; }

.subscriber-list-buttons .dropdown__options {
  width: calc(100% - 10px);
  right: 0;
  left: unset; }

.subscriber-detail {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 0px;
  margin-right: 300px; }
  .subscriber-detail__columns {
    display: flex;
    width: 100%; }
  .subscriber-detail__left-column .user-icon .status-dot {
    top: 15px;
    position: relative; }
  .subscriber-detail__left-column, .subscriber-detail__right-column {
    display: flex;
    flex-direction: column; }
  .subscriber-detail__right-column {
    border: 1px solid #E3E5EC;
    justify-content: space-between;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 300px; }
  .subscriber-detail .info-panel__cell {
    width: 33%; }
  .subscriber-detail .stat-header {
    float: left;
    font-weight: bold; }
  .subscriber-detail h3 > .stat {
    color: #b82d6a; }
  .subscriber-detail .stat {
    float: right; }
  .subscriber-detail .stat-container {
    clear: both; }

.avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 20px; }

.conversation-step {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-width: 800px;
  padding: 20px;
  width: fit-content; }
  .conversation-step--show-outline {
    box-shadow: 0 1px 5px -1px #777; }
  .conversation-step__bot-message-container {
    margin-left: 22px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px; }
    .conversation-step__bot-message-container .message {
      margin-bottom: 10px; }
    .conversation-step__bot-message-container .tooltip-container {
      left: -22px; }
  .conversation-step .user-response {
    display: flex;
    width: 100%; }
    .conversation-step .user-response__menu {
      position: relative;
      top: -30px;
      margin-left: 10px; }
  .conversation-step__dropdown {
    margin-right: 20px;
    align-self: flex-start; }
  .conversation-step__user-response-dropdown {
    margin-left: 20px; }
  .conversation-step__user-icon {
    margin-right: 10px;
    align-self: flex-end; }
  .conversation-step__bot-message {
    position: relative;
    width: 200px; }
    .conversation-step__bot-message .tooltip-container {
      margin-left: 10px;
      position: absolute;
      right: 10px;
      top: 12px; }
  .conversation-step__template-message {
    display: flex; }
    .conversation-step__template-message > div:not(:last-child) {
      margin-right: 10px; }
  .conversation-step__template-card {
    position: relative;
    width: 300px;
    border-radius: 16px 16px 16px 0px;
    border: 1px solid #E3E5EC; }
    .conversation-step__template-card__dropzone {
      cursor: pointer; }
    .conversation-step__template-card__shadowzone {
      border-radius: 16px 16px 16px 0px; }
      .conversation-step__template-card__shadowzone:hover {
        box-shadow: 0 1px 5px -1px #777;
        cursor: pointer; }
    .conversation-step__template-card__image {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      display: block; }
    .conversation-step__template-card__upload-image-prompt {
      border-bottom: 1px solid #E3E5EC;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
      color: #858790;
      padding: 10px; }
    .conversation-step__template-card__title, .conversation-step__template-card__subtitle {
      color: #092533;
      padding: 10px; }
      .conversation-step__template-card__title *, .conversation-step__template-card__subtitle * {
        font-family: Helvetica Neue, Segoe UI, Helvetica, Arial, sans-serif; }
    .conversation-step__template-card__title {
      font-weight: 600;
      padding-bottom: 0px; }
    .conversation-step__template-card__subtitle {
      color: rgba(0, 0, 0, 0.4);
      font-size: 0.9rem;
      padding-top: 3px;
      line-height: 1rem; }
    .conversation-step__template-card__title-placeholder, .conversation-step__template-card__subtitle-placeholder {
      color: #858790;
      font-weight: normal; }
    .conversation-step__template-card .conversation-step-button {
      width: 100%;
      border: none;
      border-top: 1px solid #E3E5EC; }
  .conversation-step__message-text {
    background-color: #EDEDED;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 16px;
    line-height: 1.5em;
    min-height: 41px;
    width: 100%; }
    .conversation-step__message-text div[data-slate-editor] {
      padding: 12px; }
    .conversation-step__message-text--editable:hover {
      box-shadow: 0 1px 5px -1px #777;
      cursor: pointer; }
    .conversation-step__message-text--selected {
      box-shadow: 0 1px 5px -1px #777;
      background-color: #FFFFFF; }
  .conversation-step__buttons {
    margin-top: 10px; }
    .conversation-step__buttons--no-margin {
      margin: 0; }
  .conversation-step__user-response-container {
    display: flex;
    align-items: flex-end;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto; }
  .conversation-step__user-response {
    background-color: #2F80ED;
    border-radius: 16px;
    color: white;
    line-height: 1.5em;
    min-height: 41px;
    padding: 12px;
    width: 200px; }
    .conversation-step__user-response--editable:hover {
      box-shadow: 0 1px 5px -1px #777;
      cursor: pointer; }
    .conversation-step__user-response--selected {
      box-shadow: 0 1px 5px -1px #777; }
  .conversation-step__user-response-input {
    background: transparent;
    color: white; }
  .conversation-step__user-response-user-icon {
    margin-left: 10px; }
  .conversation-step__placeholder-text-buttons {
    flex-direction: column;
    margin-left: auto;
    width: 40%; }
  .conversation-step__image-message {
    width: 100%;
    min-height: 41px;
    background-color: #EDEDED;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 16px;
    padding: 12px; }
    .conversation-step__image-message__dropzone {
      width: 100%;
      min-height: 150px;
      display: flex;
      align-items: center;
      justify-items: center; }
      .conversation-step__image-message__dropzone img,
      .conversation-step__image-message__dropzone video {
        width: 100%; }
    .conversation-step__image-message--editable:hover {
      box-shadow: 0 1px 5px -1px #777;
      cursor: pointer; }
    .conversation-step__image-message--selected {
      box-shadow: 0 1px 5px -1px #777;
      background-color: #FFFFFF; }
  .conversation-step__ab-marker {
    background-color: #feab35;
    color: white;
    border-radius: 16px;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: 16px;
    left: 92%; }

.conversation-step-button {
  position: relative;
  width: 200px; }
  .conversation-step-button .button-row {
    position: absolute;
    bottom: -30px; }
  .conversation-step-button__text {
    border: 1px solid #E3E5EC;
    font-weight: 600;
    min-height: 41px;
    text-align: center; }
    .conversation-step-button__text div[data-slate-editor] {
      color: #2F80ED;
      padding: 10px; }
    .conversation-step-button__text--editable:hover {
      box-shadow: 0 1px 5px -1px #777;
      cursor: pointer; }
    .conversation-step-button__text--selected {
      box-shadow: 0 1px 5px -1px #777; }
    .conversation-step-button__text--no-top-border-radius {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important; }
  .conversation-step-button:first-child .conversation-step-button__text {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px; }
  .conversation-step-button:first-child:not(:last-child) .conversation-step-button__text--with-top-border {
    border-bottom: none; }
  .conversation-step-button:last-child .conversation-step-button__text {
    border-top: none;
    border-bottom-right-radius: 16px; }
    .conversation-step-button:last-child .conversation-step-button__text--with-top-border {
      border-top: 1px solid #E3E5EC; }
  .conversation-step-button:not(:first-child):not(:last-child) .conversation-step-button__text {
    border-radius: 0;
    border-top: none; }
    .conversation-step-button:not(:first-child):not(:last-child) .conversation-step-button__text--with-top-border {
      border-bottom: none;
      border-top: 1px solid #E3E5EC; }
  .conversation-step-button__input {
    text-align: center; }
    .conversation-step-button__input-placeholder {
      color: #858790;
      font-weight: normal;
      text-align: left; }
  .conversation-step-button__button-types {
    background: #FFFFFF;
    margin: 5px 0;
    top: 0px;
    z-index: 3; }

.quick-replies {
  display: flex;
  flex-direction: row; }
  .quick-replies .quick-reply {
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #2f80ed;
    margin-right: 10px;
    position: relative;
    width: fit-content; }
    .quick-replies .quick-reply div[data-slate-editor='true'] {
      border: none;
      color: #2f80ed;
      font-size: 1rem;
      font-weight: 600;
      height: auto;
      min-width: 100px;
      padding: 8px 8px 10px;
      text-align: center; }
    .quick-replies .quick-reply .conversation-step-button__button-types {
      left: 0px;
      position: absolute;
      top: 36px; }
    .quick-replies .quick-reply .separated-button-row {
      position: absolute; }

.select-step-modal .message {
  padding: 10px;
  border-bottom: 1px solid #E3E5EC; }
  .select-step-modal .message:hover {
    box-shadow: 0 1px 5px -1px #777;
    cursor: pointer; }

.conversation-builder {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  background: #FFFFFF;
  border: 1px solid #E3E5EC; }
  .conversation-builder__main {
    width: 70%;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #E3E5EC; }
  .conversation-builder__bottom-modal {
    position: relative;
    width: 100%;
    border-top: 1px solid #E3E5EC;
    min-height: 85px;
    padding: 35px 20px; }
    .conversation-builder__bottom-modal-close-button {
      position: absolute;
      top: -6px;
      right: 6px;
      color: #BA2A6B;
      background: none;
      border: none;
      padding: 0;
      font-size: 34px; }
      .conversation-builder__bottom-modal-close-button:focus, .conversation-builder__bottom-modal-close-button:active {
        outline: none; }

.conversation-builder .controls,
.scenario-builder .controls {
  width: 100%;
  display: flex;
  flex-direction: row; }
  .conversation-builder .controls .section,
  .scenario-builder .controls .section {
    align-items: center;
    border-top: 1px solid #E3E5EC;
    display: flex;
    height: 60px;
    justify-content: center;
    padding: 10px 20px; }
    .conversation-builder .controls .section:not(:last-child),
    .scenario-builder .controls .section:not(:last-child) {
      border-right: 1px solid #E3E5EC; }
    .conversation-builder .controls .section.add-message,
    .scenario-builder .controls .section.add-message {
      flex-grow: 1; }
    .conversation-builder .controls .section.errors,
    .scenario-builder .controls .section.errors {
      flex-shrink: 1;
      font-weight: 700; }
  .conversation-builder .controls .button,
  .scenario-builder .controls .button {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    background: #BA2A6B;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    width: 95%;
    border-radius: 4px; }
    .conversation-builder .controls .button:active, .conversation-builder .controls .button:focus,
    .scenario-builder .controls .button:active,
    .scenario-builder .controls .button:focus {
      outline: none; }
    .conversation-builder .controls .button:last-child,
    .scenario-builder .controls .button:last-child {
      font-weight: bold;
      border-right: none; }
    .conversation-builder .controls .button--active,
    .scenario-builder .controls .button--active {
      color: #345F7B; }

.row {
  display: flex;
  justify-content: space-between; }
  .row > div {
    flex-grow: 1; }

.messages-sidebar {
  flex: 0 0 35%;
  margin-left: 10px; }

.tooltip-container.preview {
  bottom: 0px;
  float: left;
  height: 14px;
  margin-bottom: 0px;
  margin-right: 10px;
  position: relative;
  top: 4px; }
  .tooltip-container.preview img {
    height: 14px;
    margin-bottom: 3px; }
  .tooltip-container.preview::after {
    line-height: 1rem;
    max-width: 100px;
    min-width: 80px;
    text-align: center;
    white-space: normal; }

.configuration__section-header {
  font-size: 20px;
  margin-bottom: 15px; }

.configuration__facebook-page-name {
  font-weight: bold; }
  .configuration__facebook-page-name:before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 32px;
    background-color: #2F80ED;
    margin-right: 5px; }

.configuration__facebook-page-name-disabled:before {
  background-color: #FFAC33; }

.configuration__disconnect-facebook-page {
  margin-right: 10px; }

.get-started {
  display: flex;
  align-items: center; }
  .get-started__toggle {
    margin-left: 20px; }

.organization-fields-config {
  max-width: 400px; }

.dashboard .row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px; }
  .dashboard .row .card {
    margin: 0px 6px; }

.dashboard .stat {
  font-size: 1.6rem;
  color: #345e7a;
  font-weight: bold; }

.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px; }
  .row > .card,
  .row > .info-panel {
    margin: 0px 6px; }
    .row > .card:first-child,
    .row > .info-panel:first-child {
      margin-left: 0px; }
    .row > .card:last-child,
    .row > .info-panel:last-child {
      margin-right: 0px; }

.tooltip-container.unexpected-responses {
  position: relative;
  top: 4px;
  margin-left: 10px; }
  .tooltip-container.unexpected-responses img {
    height: 14px;
    margin-bottom: 3px; }
  .tooltip-container.unexpected-responses::after {
    width: 200px;
    white-space: normal;
    line-height: 1rem; }

.image {
  max-width: 100%; }

.login {
  width: 100%;
  height: 100%;
  position: relative;
  background: #305e7e;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #305e7e 0%, #ba2a6b 50%, #ffad18 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #305e7e 0%, #ba2a6b 50%, #ffad18 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #305e7e 0%, #ba2a6b 50%, #ffad18 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#305e7e', endColorstr='#ffad18',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */ }
  .login__paloma {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #FFFFFF;
    font-size: 28px; }
  .login__title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 32px;
    color: #FFFFFF; }
  .login__form-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .login__form {
    padding: 20px;
    background: #FFFFFF;
    border: 1px solid #E3E5EC; }
  .login .request-password-reset {
    margin-top: 10px;
    font-size: .8rem;
    text-align: right; }

.conversation-publisher strong {
  font-weight: bold; }

.conversation-publisher__subscriber-list-select {
  max-width: 400px; }

.conversation-publisher__publish-button {
  font-size: 16px;
  margin-left: 0;
  margin-top: 20px;
  width: 400px; }

.conversation-publisher__target-filter {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-bottom: 20px; }
  .conversation-publisher__target-filter__field-dropdown {
    color: #A90F21;
    width: 250px;
    margin-right: 20px; }
  .conversation-publisher__target-filter__condition-dropdown {
    float: left;
    color: blue;
    width: 250px;
    margin-right: 20px; }
  .conversation-publisher__target-filter__value-input {
    width: 250px;
    max-height: 35px; }
  .conversation-publisher__target-filter__value-select {
    width: 250px; }
  .conversation-publisher__target-filter__delete {
    margin-top: 3px;
    margin-left: 20px;
    width: 29px;
    display: inline-block;
    border: 1px solid #E3E5EC;
    border-radius: 4px;
    height: 31px;
    padding: 10px;
    font-size: 14px;
    background-image: url("../images/ic_minus.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: white; }
  .conversation-publisher__target-filter__date {
    width: 250px; }

.conversation-publisher__num-targeted {
  font-weight: bold; }

.conversation-publisher__ab-publish {
  margin-bottom: 20px;
  margin-top: 25px; }

.conversation-publisher__test-group-size {
  width: 350px; }

.conversation-publisher__test-group-slider {
  height: 50px;
  margin-top: 25px; }

.conversation-publisher__slider-value {
  position: relative;
  top: 25px;
  left: -5px; }

.conversation-publisher__test-group-previews {
  background-color: #F0F1F5;
  border-radius: 7px;
  width: 350px;
  margin-top: 20px;
  margin-bottom: 45px;
  border: 1px solid lightgray;
  height: 60px;
  display: flex;
  padding-left: 15px;
  padding-top: 20px; }
  .conversation-publisher__test-group-previews__a-preview {
    width: 100px;
    display: flex; }
  .conversation-publisher__test-group-previews__b-preview {
    width: 100px;
    display: flex; }
  .conversation-publisher__test-group-previews__winner-preview {
    width: 150px;
    display: flex; }

.conversation-publisher__preview-percent {
  padding-top: 2px;
  margin-left: 10px; }

.conversation-publisher__ab-label {
  background-color: #FEAB35;
  color: white;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center; }

.conversation-publisher .winner-label {
  width: 60px;
  border-radius: 6px; }

.conversation-publisher__end-datetime-selector {
  display: flex;
  align-items: left; }

.conversation-publisher__end-date-picker {
  width: 150px;
  margin-right: 20px; }
  .conversation-publisher__end-date-picker .paloma-date-picker__date-picker {
    width: 150px; }

.conversation-publisher__num-engagements-input[type=text] {
  height: 35px; }

.conversation-publisher__time-picker input {
  height: 35px; }

.conversation-publisher__end-date-selector-label {
  margin-bottom: 10px; }

.conversation-publisher__end-time-selector-label {
  margin-bottom: 10px; }

.conversation-publisher__trigger-section {
  margin-top: 20px; }

.conversation-publisher__notice-section {
  align-items: center;
  display: flex;
  font-style: italic;
  margin-top: 20px; }

.conversation-publisher__notice-section-icon {
  margin-right: 8px; }

.conversation-publisher__heading {
  font-weight: bold; }

.conversation-publisher .toggle {
  margin-bottom: 5px; }

.test-send .Select {
  margin-bottom: 15px; }

.test-send .modal__controls {
  display: flex; }
  .test-send .modal__controls .paloma-button {
    margin: 0px 5px; }

.test-send .paloma-button {
  margin-left: 0px;
  width: 100%;
  max-width: 400px; }

.scheduler .radio {
  margin: 20px 10px; }
  .scheduler .radio label {
    margin-left: 10px;
    margin-right: 20px; }

.scheduler .date-time {
  display: flex;
  flex-direction: row; }
  .scheduler .date-time .paloma-date-picker__date-picker {
    font-size: 1rem; }
  .scheduler .date-time .paloma-date-picker {
    margin-right: 15px; }
    .scheduler .date-time .paloma-date-picker input {
      border: 1px solid #ccc; }

.organization-switcher {
  background: #b6306a;
  color: white;
  display: flex;
  flex-direction: row;
  padding: 10px 20px;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  width: 100%;
  z-index: 1000; }
  .organization-switcher .dropdown {
    margin: 0px; }
  .organization-switcher .dropdown__options {
    width: unset; }
    .organization-switcher .dropdown__options--open {
      height: 500px;
      overflow-y: scroll;
      border-bottom: 1px solid #E3E5EC;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px; }
    .organization-switcher .dropdown__options:last-child {
      border-radius: none; }
  .organization-switcher .buttons {
    display: flex;
    flex-direction: row; }
  .organization-switcher h5 {
    font-weight: bold; }

.card.event-graph-card {
  padding: 0px;
  margin-bottom: 10px; }

.event-graph-container {
  display: flex;
  flex-direction: row; }
  .event-graph-container .subscriber-flow {
    border-left: 1px solid #EFF1F5;
    max-width: 245px; }

.event-graph {
  flex-grow: 1;
  padding: 20px; }
  .event-graph .tick {
    font-size: 12px; }
  .event-graph .label {
    font-weight: 900; }

.event-summary {
  border-spacing: 0px; }
  .event-summary th {
    font-weight: 900; }
  .event-summary td {
    border-top: 1px solid #EFF1F5; }
  .event-summary td,
  .event-summary th {
    padding: 20px 0px; }
  .event-summary tr:last-child td {
    border-bottom: 1px solid #EFF1F5; }

.rc-tooltip.step-mini-preview .rc-tooltip-inner {
  border-top-color: white;
  background: white;
  color: black; }

.rc-tooltip.step-mini-preview .rc-tooltip-arrow {
  display: none; }

.rc-tooltip.step-mini-preview .conversation-step {
  min-width: 0px; }

.funnel-step-card {
  height: 100%; }

html {
  box-sizing: border-box;
  overflow: hidden;
  font-size: 14px; }

body {
  font-family: sans-serif;
  overflow: auto; }

*,
*:before,
*:after {
  box-sizing: inherit; }

html,
body,
#root,
.app {
  width: 100%;
  height: 100%;
  margin: 0; }
